import React, { useEffect, useState, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { io } from "socket.io-client";
import { useLocation } from "react-router-dom";

let socket;
const ServerNotResponds = lazy(() => import("./serverNotResponds/ServerNotResponds"));
const Login = lazy(() => import("./login/Login"));
const Register = lazy(() => import("./register/Register"));
const SetPassword = lazy(() => import("./setPassword/SetPassword"));
const Main = lazy(() => import("./main/Main"));
const VideoChats = lazy(() => import("./call/call"));
const Desk = lazy(() => import("./desk/Desk"));
export default function Router(props) {
    let location = useLocation();
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(true);
    const [isLogin, setIsLogin] = useState(false);
    const [isServerResponds, setIsServerResponds] = useState(true);
    const [serverUrl, setServerUrl] = useState("");
    const [isMinSize, setIsMinSize] = useState(true);
    // eslint-disable-next-line
    const [callChat, setCall] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const sync = async () => {
            let r = await window.checkLogin();
            setIsLogin(r);
            setIsServerResponds((await window.checkServer()) === 200 ? true : false);
        };

        window.setIsMinSize = setIsMinSize;
        setIsMinSize(window.innerWidth > 420 ? window.mobileCheck() : true);
        sync();
        setServerUrl(window.getServerUrl());
        window.trueLogout = setIsLogin;
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
            setIsMinSize(window.innerWidth > 420 ? window.mobileCheck() : true);
        });
        window.addEventListener("contextmenu", (e) => {
            e.preventDefault();
        });
    }, []);

    useEffect(() => {
        if (serverUrl) {
            socket = io(serverUrl);
            setIsLoading(false);
        }
    }, [serverUrl]);

    return (
        <Suspense fallback={<></>}>
            {!isServerResponds ? (
                <ServerNotResponds
                    serverUrl={serverUrl}
                    isMinSize={isMinSize}
                    setIsServerResponds={setIsServerResponds}
                />
            ) : isLogin ? (
                <Routes>
                    <Route
                        exact
                        path="/call/:shortCut"
                        element={
                            <VideoChats
                                location={location}
                                params={{
                                    shortCut: location.pathname.split("/")[location.pathname.split("/").length - 1],
                                }}
                                serverUrl={serverUrl}
                            />
                        }
                    />
                    <Route exact path="/draw/:shortCut" element={<Desk socket={socket} serverUrl={serverUrl} />} />
                    <Route
                        path="*"
                        element={
                            <Main
                                setIsLogin={setIsLogin}
                                setCall={setCall}
                                isMinSize={isMinSize}
                                socket={socket}
                                location={props.location}
                                serverUrl={serverUrl}
                                width={width}
                            />
                        }
                    />
                </Routes>
            ) : (
                <Routes>
                    <Route
                        exact
                        path="/register"
                        element={<Register setIsLogin={setIsLogin} isMinSize={isMinSize} serverUrl={serverUrl} />}
                    />
                    <Route
                        exact
                        path="/setPassword"
                        element={<SetPassword setIsLogin={setIsLogin} isMinSize={isMinSize} serverUrl={serverUrl} />}
                    />
                    <Route
                        path="*"
                        element={<Login setIsLogin={setIsLogin} isMinSize={isMinSize} serverUrl={serverUrl} />}
                    />
                </Routes>
            )}
        </Suspense>
    );
}
