/* eslint-disable */
import axios from "axios";
import Cookies from "universal-cookie";
import publicIp from "public-ip";
import CryptoJS from "crypto-js";

const cookies = new Cookies();
const serverUrl = window.location.hostname !== "room-ix.ru" ? "https://95.161.172.222" : "https://77.222.52.226";
const dev = window.location.hostname !== "room-ix.ru";
window.domain = "https://" + window.location.host;

console.log(window.domain, serverUrl);

var expiryDate = new Date(Date.now() + 24 * 7 * 60 * 60 * 1000);
var password = "password";
var OSName = "Unknown";

if (window.navigator.userAgent.indexOf("Windows NT 10.0") !== -1) OSName = "Windows 10";
if (window.navigator.userAgent.indexOf("Windows NT 6.3") !== -1) OSName = "Windows 8.1";
if (window.navigator.userAgent.indexOf("Windows NT 6.2") !== -1) OSName = "Windows 8";
if (window.navigator.userAgent.indexOf("Windows NT 6.1") !== -1) OSName = "Windows 7";
if (window.navigator.userAgent.indexOf("Windows NT 6.0") !== -1) OSName = "Windows Vista";
if (window.navigator.userAgent.indexOf("Windows NT 5.1") !== -1) OSName = "Windows XP";
if (window.navigator.userAgent.indexOf("Windows NT 5.0") !== -1) OSName = "Windows 2000";
if (window.navigator.userAgent.indexOf("Mac") !== -1) OSName = "Mac/iOS";
if (window.navigator.userAgent.indexOf("X11") !== -1) OSName = "UNIX";
if (window.navigator.userAgent.indexOf("Linux") !== -1) OSName = "Linux";
function createSettings({
    size,
    selectImage,
    theme,
    themeIsSystem,
    language,
    transpery,
    timeToDeleteCache,
    visibilityEncryptMessages,
    localVisibilityEncryptMessages,
    fileDownloadPath,
}) {
    let settings = {};
    if (cookies.get("params")) settings = cookies.get("params");
    return {
        size: size === undefined ? (settings.size === undefined ? true : settings.size) : size,

        selectImage:
            selectImage === undefined ? (settings.selectImage === undefined ? 5 : settings.selectImage) : selectImage,
        theme: theme === undefined ? (settings.theme === undefined ? "light" : settings.theme) : theme,
        themeIsSystem:
            themeIsSystem === undefined
                ? settings.themeIsSystem === undefined
                    ? false
                    : settings.themeIsSystem
                : themeIsSystem,
        language: language === undefined ? (settings.language === undefined ? "ru" : settings.language) : language,
        timeToDeleteCache:
            timeToDeleteCache === undefined
                ? settings.timeToDeleteCache === undefined
                    ? 4
                    : settings.timeToDeleteCache
                : timeToDeleteCache,

        transpery:
            transpery === undefined ? (settings.transpery === undefined ? false : settings.transpery) : transpery,
        fileDownloadPath:
            fileDownloadPath === undefined
                ? settings.fileDownloadPath === undefined
                    ? "/Roomix"
                    : settings.fileDownloadPath
                : fileDownloadPath,
        visibilityEncryptMessages:
            visibilityEncryptMessages === undefined
                ? settings.visibilityEncryptMessages === undefined
                    ? true
                    : settings.visibilityEncryptMessages
                : visibilityEncryptMessages,

        localVisibilityEncryptMessages:
            localVisibilityEncryptMessages === undefined
                ? settings.localVisibilityEncryptMessages === undefined
                    ? []
                    : settings.localVisibilityEncryptMessages
                : localVisibilityEncryptMessages,
        images: [
            serverUrl + "/images/1.jpg",
            serverUrl + "/images/2.png",
            serverUrl + "/images/3.jpg",
            serverUrl + "/images/4.png",

            serverUrl + "/images/1_back.png",
            serverUrl + "/images/2_back.png",
            serverUrl + "/images/3_back.png",
        ],
    };
}
// localVisibilityEncryptMessages
const generateHash = (json) => {
    var string = JSON.stringify(json);
    var ciphertext = CryptoJS.AES.encrypt(string, password).toString();
    return ciphertext;
};
function readHashFile(string, secret) {
    var originalText = "";
    var bytes = CryptoJS.AES.decrypt(string, secret);
    try {
        originalText = bytes.toString(CryptoJS.enc.Utf8);
    } catch (e) {
        originalText = "";
    }
    return originalText;
}
const readHash = (string) => {
    var bytes = CryptoJS.AES.decrypt(string, password);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(originalText);
};
function generateHashFile(string, secret) {
    console.log(string, secret);
    var ciphertext = CryptoJS.AES.encrypt(string, secret).toString();
    return ciphertext;
}
window.getServerUrl = () => {
    return serverUrl;
};
window.checkServer = async () => {
    let res = {};
    await axios
        .get(`${serverUrl}/checkServer`)
        .then((r) => {
            res = r.status;
        })
        .catch((e) => {
            console.log(e);
            res = e;
        });
    return res;
};

window.checkLogin = () => {
    return cookies.get("roomix") ? true : false;
};
window.logout = async () => {
    cookies.set("roomix", "", { path: "/", maxAge: -1 });
    cookies.set("params", "", { path: "/", maxAge: -1 });
    return true;
};

window.readCokies = ({ name }) => {
    return cookies.get(name);
};
window.createCokies = ({ path, name, string }) => {
    cookies.set(name, string, { path, maxAge: expiryDate });
};

window.login = async ({ email, password }) => {
    let res = {};
    let ip = "";
    if (!dev) {
        ip = "http://77.222.52.226";
    } else {
        ip = await publicIp.v4();
    }
    console.log(ip);
    console.log("login");

    if (!email || !password) {
        alert("Введите почту и пароль");
        return false;
    }

    if (window.checkLogin()) {
        alert("Вход уже выполнен");
        return false;
    }

    await axios
        .get(serverUrl + "/api/user/login", {
            params: {
                email: email,
                password: password,
                time: Date.now(),
                desktopName: OSName,
                platform: process.platform,
                ip,
            },
        })
        .then((r) => {
            if (r.data.internal_error) {
                res = r.data;
            } else {
                window.createCokies({
                    path: "/",
                    name: "roomix",
                    string: generateHash({ email, password, _id: r.data._id }),
                });
                res = r.data;
            }
        });
    return res;
};
window.getMyInfo = async (from) => {
    if (window.checkLogin()) {
        return readHash(window.readCokies({ name: "roomix" }));
    } else {
        window.trueLogout(false);
        window.logout();
        return false;
    }
};
window.getMy = async () => {
    if (!window.checkLogin()) {
        return false;
    }
    window.createCokies({
        path: "/",
        name: "params",
        string: JSON.stringify(
            createSettings({
                size: undefined,
                selectImage: undefined,
                theme: undefined,
                themeIsSystem: undefined,
                language: undefined,
                transpery: undefined,
                timeToDeleteCache: undefined,
                fileDownloadPath: undefined,
            })
        ),
    });

    var { email, password } = readHash(window.readCokies({ name: "roomix" }));
    let res = {};
    let ip = "";
    if (!dev) {
        ip = "http://77.222.52.226";
    } else {
        ip = await publicIp.v4();
    }
    await axios
        .get(serverUrl + "/api/user/login", {
            params: {
                email: email,
                password: password,
                time: Date.now(),
                desktopName: OSName,
                platform: process.platform,
                ip,
            },
        })
        .then((r) => {
            if (r.data.internal_error) {
                res = r.data;
            } else {
                res = r.data;
            }
        });
    return res;
};
window.setPassword = async ({ email, key, newPassword }) => {
    var res;
    await axios.get(serverUrl + "/api/user/setPasswopd", { params: { email, key, newPassword } }).then((r) => {
        res = r.data;
    });
    return res;
};
window.register = async (args) => {
    let res = {};
    if (!args.password || !args.email || !args.firstName || !args.secondName || !args.patronymic) {
        return false;
    }
    if (window.checkLogin()) {
        alert("Требуется выйти чтобы зарегистрировать пользователя");
        return false;
    }
    let ip = "";
    if (!dev) {
        ip = "http://77.222.52.226";
    } else {
        ip = await publicIp.v4();
    }
    console.log(ip);
    args = { ...args, time: Date.now(), desktopName: OSName, platform: process.platform, ip };
    await axios.get(serverUrl + "/api/user/create", { params: JSON.stringify(args) }).then((r) => {
        if (r.data._id) {
            window.createCokies({
                path: "/",
                name: "roomix",
                string: generateHash({ email: args.email, password: args.password }),
            });
            res = true;
        } else {
            res = r.data;
        }
    });
    return res;
};

window.verify = async (e, args) => {
    let ip = "";
    if (!dev) {
        ip = "http://77.222.52.226";
    } else {
        ip = await publicIp.v4();
    }
    const desktopName = OSName,
        platform = process.platform;

    let file = await window.getMyInfo("verify");
    if (!file) {
        return;
    }
    if (!file) {
        return false;
    } else {
        return file._id
            ? await axios.get(serverUrl + "/api/user/update", { params: { ...args, _id: file._id } }).then((r) => {
                  if (r.data.internal_error) {
                      //   res = r.data;
                  } else {
                      let res = {
                          verify: false,
                          _id: file._id,
                          ip,
                          desktopName,
                          platform,
                          time: Date.now(),
                      };
                      r.data.settings.security.map((e) => {
                          if (e.desktopName === desktopName) {
                              res = {
                                  verify: !e.banned,
                              };
                          } else {
                          }
                      });
                      if (r.data.settings.security.length === 0) {
                          console.log("u here 1", res);
                          res = {
                              verify: true,
                          };
                      }
                      if (!res.verify) {
                          console.log("u here 2", res);
                          axios.get(serverUrl + "/api/room/notificationRoom", { params: { ...res } });
                      }
                      return res;
                  }
              })
            : false;
    }
};

window.getSettings = async () => {
    return cookies.get("params");
};

//TODO: YES
window.getMyRoom = async (e) => {
    let res = {};
    let my_info = await window.getMyInfo("getMyRoom");
    if (!my_info) {
        return;
    }
    await axios.get(serverUrl + "/api/room/getMyRooms", { params: { _id: my_info._id } }).then((r) => {
        if (r.data.internal_error) {
            res = false;
        } else {
            res = r.data;
        }
    });
    console.log(res);
    return res;
};
window.getRoom = async (shortcut) => {
    let res = {};
    let my_info = await window.getMyInfo("getRoom");
    if (!my_info) {
        return;
    }
    await axios.get(serverUrl + "/api/room/get", { params: { _id: my_info._id, shortcut } }).then((r) => {
        if (r.data.internal_error) {
            res = false;
        } else {
            res = r.data;
        }
    });
    return res;
};
window.getMessage = async ({ roomId, skip, limit }) => {
    let res = {};
    let my_info = await window.getMyInfo("getMessage");
    if (!my_info) {
        return;
    }
    await axios
        .get(serverUrl + "/api/message/get", {
            params: { authorId: my_info._id, roomId, skip, limit },
        })
        .then((r) => {
            if (r.data.internal_error) {
                res = false;
            } else {
                res = r.data;
            }
        });
    return res;
};
window.readHash = async (string, key) => {
    return readHashFile(string, key);
};
window.infoServer = async () => {
    let res = {};
    await axios.get(`${serverUrl}/infoServer`).then((r) => {
        res = r.data;
    });
    return res;
};
window.getAllFiles = async ({ type = "" }) => {
    let res = {};
    let my_info = await window.getMyInfo("getAllFiles");
    if (!my_info) {
        return;
    }
    await axios
        .get(serverUrl + "/api/file/getAll", { params: { _id: my_info._id, type } })
        .then((r) => {
            if (r.data.internal_error) {
                res = false;
            } else {
                res = r.data;
            }
        })
        .catch((e) => {
            console.log(e, "error");
        });

    return res;
};

window.sendMessage = async ({ roomId, text, type, src, data, usersOnline }) => {
    let my_info = await window.getMyInfo("sendMessage");
    if (!my_info) {
        return;
    }
    let res = {};
    await axios
        .post(serverUrl + "/api/message/send", { authorId: my_info._id, roomId, text, type, src, data, usersOnline })
        .then((r) => {
            if (r.data.internal_error) {
                res = false;
            } else {
                res = r.data;
            }
        });
    return res;
};
window.createRoom = async (nameRoom, roomTime) => {
    let res = {};
    let time = {
        years: 0,
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    };
    console.log(nameRoom, roomTime);
    if (roomTime)
        roomTime.split(" ").map((e) => {
            if (e.split("-").length !== 2) return;
            if (e.split("-")[1].includes("год") || e.split("-")[1].includes("лет")) {
                time.years = e.split("-")[0] * 31536000000;
            }
            if (e.split("-")[1].includes("месяц")) {
                time.months = e.split("-")[0] * 2419200000;
            }
            if (e.split("-")[1] == "дней" || e.split("-")[1] == "день") {
                time.days = e.split("-")[0] * 86400000;
            }
            if (e.split("-")[1].includes("час")) {
                time.hours = e.split("-")[0] * 3600000;
            }
            if (e.split("-")[1].includes("минут")) {
                time.minutes = e.split("-")[0] * 60000;
            }
            if (e.split("-")[1].includes("секунд")) {
                time.seconds = e.split("-")[0] * 1000;
            }
        });
    console.log(time);
    var timeSend = 0;
    Object.keys(time).map((e) => {
        timeSend += time[e];
    });
    if (timeSend !== 0) {
        timeSend += Date.now();
    }

    console.log(timeSend);

    let my_info = await window.getMyInfo("createRoom");
    if (!my_info) {
        return;
    }
    await axios.get(serverUrl + "/api/room/create", { params: { _id: my_info._id, nameRoom, roomTime } }).then((r) => {
        if (r.data.internal_error) {
            // CreateNotification("Обновление", r.data.description);
            res = r.data;
        } else {
            res = true;
        }
    });
    return res;
};

window.getUser = async (string) => {
    let res;
    await axios.get(serverUrl + "/api/user/get", { params: { string: string.string, type: string.type } }).then((r) => {
        if (r.data.internal_error) {
            res = false;
        } else {
            res = r.data;
        }
    });
    return res;
};
window.getAllUser = async () => {
    let res;
    await axios.get(serverUrl + "/api/user/getAll").then((r) => {
        if (r.data.internal_error) {
            res = false;
        } else {
            res = r.data;
        }
    });
    return res;
};
window.getInfoUser = async ({ _id, rooms }) => {
    let res;
    await axios.get(serverUrl + "/api/user/getInfoUser", { params: { _id, rooms } }).then((r) => {
        if (r.data.internal_error) {
            res = false;
        } else {
            res = r.data;
        }
    });
    return res;
};
window.addRoomUser = async (idUser, idRoom) => {
    let res = {};
    await axios
        .get(serverUrl + "/api/room/addUser", {
            params: { idUser, idRoom },
        })
        .then((r) => {
            if (r.data.internal_error) {
                res = false;
            } else {
                res = true;
            }
        });
    return res;
};
window.createNotification = (args) => {
    console.group();
    console.log(args.title);
    console.log(args.body);
    console.groupEnd();
};
window.getHash = async (string, secret) => {
    console.log(string, secret);
    return generateHashFile(string, secret);
};
window.addKey = async ({ key, room }) => {
    let my_info = await window.getMyInfo("addKey");
    if (!my_info) {
        return;
    }
    let res = {};
    await axios
        .get(serverUrl + "/api/user/addKey", { params: { _id: my_info._id, key: key, room: room } })
        .then((r) => {
            console.log(r.data);
            if (r.data.internal_error) {
                res = r.data;
            } else {
                res = true;
            }
        });
    return res;
};
const getTypeName = (path) => {
    var pathFIle = path.split("/").slice(1);
    pathFIle.pop(0);
    pathFIle = pathFIle.join("/");
    pathFIle = "/" + pathFIle;
    var nameFile = path.split("/")[path.split("/").length - 1].split(".")[0];
    var typeFile = path.split("/")[path.split("/").length - 1].split(".")[
        path.split("/")[path.split("/").length - 1].split(".").length - 1
    ];
    return { pathFIle, nameFile, typeFile };
};
window.sendFile = async ({ path, data, base64, type, name, from, info }) => {
    let my_info = await window.getMyInfo("sendFile");
    if (!my_info) {
        return;
    }
    let res = {};
    if (from == "canvas") {
        console.log("send canvas");
        await axios
            .post(serverUrl + "/api/file/save", { data, id: my_info._id, type, base64 })
            .then((r) => {
                if (r.data.internal_error) {
                    res = false;
                } else {
                    res = r.data;
                }
            })
            .catch((e) => {});
    } else if (from === "desktop") {
        var { typeFile } = getTypeName(name);
        console.log("send desktop");
        try {
            const result = await axios({
                url: serverUrl + "/api/file/save",
                method: "post",
                maxContentLength: Infinity,
                maxBodyLength: Infinity,
                data: {
                    name,
                    from,
                    id: my_info._id,
                    type: type ? type : typeFile,
                    typeFile,
                    base64,
                    info,
                },
            });
            if (result.data.internal_error) {
                res = false;
            } else {
                res = result.data;
            }
        } catch (e) {
            console.log(e.data);
        }
    } else if (from === "clipboard") {
        try {
            const result = await axios({
                url: serverUrl + "/api/file/save",
                method: "post",
                maxContentLength: Infinity,
                maxBodyLength: Infinity,
                data: {
                    name,
                    from,
                    id: my_info._id,
                    type: type ? type : "png",
                    typeFile: "png",

                    base64,
                },
            });

            if (result.data.internal_error) {
                res = false;
            } else {
                res = result.data;
            }
        } catch (e) {
            console.log(e.data);
        }
    }

    return res;
};

window.mobileCheck = function () {
    let check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

window.changeSettings = async (params) => {
    window.createCokies({
        path: "/",
        name: "params",
        string: JSON.stringify(
            createSettings({
                size: params.size,
                selectImage: params.selectImage,
                theme: params.theme,
                themeIsSystem: params.themeIsSystem,
                language: params.language,
                transpery: params.transpery,
                timeToDeleteCache: params.timeToDeleteCache,
                fileDownloadPath: params.fileDownloadPath,
                localVisibilityEncryptMessages: params.localVisibilityEncryptMessages,
                visibilityEncryptMessages: params.visibilityEncryptMessages,
            })
        ),
    });
    return cookies.get("params");
};
window.getWeb = async ({ url }) => {
    let res = {};
    await axios
        .get(url)
        .then((r) => {
            res = r.data;
        })
        .catch((e) => {
            res = e;
        });
    return res;
};

window.openModal = (args) => {
    var pathUrl;
    if (args.modal === "start") {
        if (args.type === "call") {
            if (window.RTCPeerConnection === undefined) {
                alert("Ваш браузер не поддерживается");
                return false;
            }

            pathUrl = `/call/${args.shortCut}?id=${args.myId}&room=${args.room}&name=${args.name}`;
        } else if (args.type === "desk") {
            pathUrl = `/draw/${args.shortCut}?id=${args.myId}&room=${args.room}`;
        }

        window.open(pathUrl, "_blank");
        return true;
    } else if (args.modal === "finish") {
        window.close();
    }
};
window.deleteFile = async ({ fileId, src }) => {
    let my_info = await window.getMyInfo("deleteFile");
    if (!my_info) {
        return;
    }
    let res = {};
    await axios
        .get(`${serverUrl}/api/file/delete`, { params: { fileId, src, _id: my_info._id } })
        .then((r) => {
            res = r.data;
        })
        .catch((e) => {
            res = e;
        });
    return res;
};

let deferredPrompt;
let pwa = window.readCokies({ name: "pwa" });
let addPwa;

if (pwa) {
    if (pwa.view) {
        addPwa = false;
    } else {
        if (Date.now() - pwa.date > 1000 * 60 * 60 * 24) {
            addPwa = true;
        } else {
            addPwa = false;
        }
    }
} else {
    addPwa = true;
}
window.getAllCookie = () => {
    var c = [];
    var cookie = cookies.getAll();
    Object.keys(cookie).map((e) => {
        c.push({
            name: e,
            value: cookie[e],
        });
    });
    return c;
};

window.onload = () => {
    const addBtn = document.getElementById("add-button");
    window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        deferredPrompt = e;
        if (addPwa) {
            // addBtn.style.display = "block";
            addBtn.addEventListener("click", (e) => {
                addBtn.style.display = "none";
                deferredPrompt.prompt();
                deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === "accepted") {
                        console.log("User accepted the A2HS prompt");
                    } else {
                        console.log("User dismissed the A2HS prompt");
                        addPwa = false;
                        createCokies({ path: "/", name: "pwa", string: { view: false, date: Date.now() } });
                    }
                    deferredPrompt = null;
                });
            });
        }
    });
};
window.deleteRoom = async (chat) => {
    let { _id } = await window.getMyInfo("deleteRoom");
    let res = false;
    // var answer = confirm("Данное действие нельзя отменить, Продолжить?");
    if (true)
        await await axios
            .get(`${serverUrl}/api/room/delete`, { params: { chatId: chat._id, _id, exit: chat.exit } })
            .then((r) => {
                res = r.data;
            })
            .catch((e) => {
                res = e;
            });
    console.log(res);
    return res;
};

window.generateLink = async ({ type, subType, count, hours, chatId }) => {
    let { _id } = await window.getMyInfo("generateLink");
    let res = {};
    await await axios
        .get(`${serverUrl}/api/link/generate`, { params: { chatId, type, subType, count, hours, _id } })
        .then((r) => {
            res = r.data;
        })
        .catch((e) => {
            res = e;
        });
    return res;
};

window.getLink = async ({ link }) => {
    let { _id } = await window.getMyInfo("getLink");
    let res = {};
    await await axios
        .get(`${serverUrl}/api/link/get`, { params: { link, _id } })
        .then((r) => {
            res = r.data;
        })
        .catch((e) => {
            res = e;
        });
    return res;
};

window.eventLink = async ({ link }) => {
    let { _id } = await window.getMyInfo("eventLink");
    let res = {};
    await await axios
        .get(`${serverUrl}/api/link/event`, { params: { link, _id } })
        .then((r) => {
            res = r.data;
        })
        .catch((e) => {
            res = e;
        });
    return res;
};
window.getMyLinks = async () => {
    let { _id } = await window.getMyInfo("getMyLinks");
    let res = {};
    await await axios
        .get(`${serverUrl}/api/link/getMyLinks`, { params: { _id } })
        .then((r) => {
            res = r.data;
        })
        .catch((e) => {
            res = e;
        });
    return res;
};
window.getLinkId = async ({ idLink }) => {
    let { _id } = await window.getMyInfo("getLinkId");
    let res = {};
    await await axios
        .get(`${serverUrl}/api/link/get/` + idLink, { params: { _id } })
        .then((r) => {
            res = r.data;
        })
        .catch((e) => {
            res = e;
        });
    return res;
};

window.changeActiveLink = async ({ link }) => {
    let { _id } = await window.getMyInfo("changeActiveLink");
    let res = {};
    await await axios
        .get(`${serverUrl}/api/link/changeActive/`, { params: { _id, link } })
        .then((r) => {
            res = r.data;
        })
        .catch((e) => {
            res = e;
        });
    return res;
};

window.getLinkUrl = async ({ link }) => {
    let { _id } = await window.getMyInfo("getLinkId");
    let res = {};
    await await axios
        .get(`${serverUrl}/api/link/url/`, { params: { _id, link } })
        .then((r) => {
            res = r.data;
        })
        .catch((e) => {
            res = e;
        });
    return res;
};

window.test = async () => {
    return false;
};
window.uploadPhoto = async ({ file, type }) => {
    let res = {};
    let { _id } = await window.getMyInfo("uploadPhoto");
    await axios
        .post(serverUrl + "/api/file/photo", { file, id: _id, type })
        .then((r) => {
            if (r.data.internal_error) {
                res = false;
            } else {
                res = true;
            }
        })
        .catch((e) => {
            log.error("sendFile " + e);
        });

    return res;
};
window.update = async (args) => {
    let res = {};
    let { _id } = await window.getMyInfo("update");
    await axios.get(serverUrl + "/api/user/update", { params: { ...args, _id } }).then((r) => {
        if (r.data.internal_error) {
            res = r.data;
        } else {
            res = true;
        }
    });
    return res;
};
window.changePassword = async ({ oldPassword, newPassword }) => {
    let res = {};
    let { _id } = await window.getMyInfo("changePassword");
    await axios.get(serverUrl + "/api/user/changePassword", { params: { _id, oldPassword, newPassword } }).then((r) => {
        res = r.data;
    });
    return res;
};
window.blockDevice = async ({ blockDevice }) => {
    let res = {};
    let { _id } = await window.getMyInfo("changePassword");
    await axios.get(serverUrl + "/api/user/block", { params: { blockDevice, _id } }).then((r) => {
        console.log(r.data);
        res = r.data;
    });
    return res;
};
window.acceptMail = async () => {
    let res = {};
    let { _id } = await window.getMyInfo("acceptMail");
    await axios.get(serverUrl + "/api/user/acceptMail", { params: { _id } }).then((r) => {
        res = r.data;
    });
    return res;
};
window.acceptMailCode = async (code) => {
    let res = {};
    let { _id } = await window.getMyInfo("acceptMailCode");
    await axios.get(serverUrl + "/api/user/acceptMailCode", { params: { _id, code } }).then((r) => {
        res = r.data;
    });
    return res;
};
window.download = (link, type) => {
    // console.log(link);
    return link;
};
// set HTTPS=true&&
window.deleteMessage = async ({ idMessage }) => {
    console.log(serverUrl, idMessage);
    let res = {};
    await axios
        .get(serverUrl + "/api/message/delete", {
            params: { idMessage },
        })
        .then((r) => {
            if (r.data.internal_error) {
                res = false;
            } else {
                res = true;
            }
        });
    return res;
};

window.checkMessages = async ({ roomId, messageCount }) => {
    let res = {};
    let { _id } = await window.getMyInfo("checkMessages");
    await axios
        .get(serverUrl + "/api/room/checkMessages", { params: { userId: _id, roomId, messageCount } })
        .then((r) => {
            if (r.data.internal_error) {
                res = false;
            } else {
                res = r.data;
            }
        });
    return res;
};
